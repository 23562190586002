import { parseISO } from 'date-fns';

export const dateFromTo = (from: string, to: string, locale: string, timezone: string | undefined): string => {
  const startDate = parseISO(from);
  const endDate = parseISO(to);

  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'short',
    timeZone: timezone,
    year: 'numeric',
  });

  return (dateTimeFormat as Intl.DateTimeFormat & { formatRange: (a: Date, b: Date) => string }).formatRange(
    startDate,
    endDate,
  );
};
